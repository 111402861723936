import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton, FunctionField,
} from 'react-admin';
import NamingsFilters from './NamingsFilters';
import Tooltip from '@mui/material/Tooltip';

const NamingsList = (props: any) => {
  return (
    <List
      {...props}
      filters={<NamingsFilters />}
    >
      <Datagrid
        sx={{ overflowX: 'auto', maxWidth: '100%' }}
      >
        <TextField source='demoId' label='#' />
        <TextField source='naming' />
        {/*<TextField source='pixel' />*/}
        <FunctionField
          label='Pixel'
          render={(record: any) => {
            const truncatedText = record.pixel.substring(0, 20);
            const fullText = record.pixel;

            return (
              <Tooltip title={fullText}>
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {truncatedText} {truncatedText < fullText && '...'}
                </div>
              </Tooltip>
            );
          }}
        />
        <FunctionField
          label='Full link'
          render={(record: any) => {
            const truncatedText = record.fullLink.substring(0, 20);
            const fullText = record.fullLink;

            return (
              <Tooltip title={fullText}>
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {truncatedText} {truncatedText < fullText && '...'}
                </div>
              </Tooltip>
            );
          }}
        />
        {/*<TextField source='fullLink' />*/}
        <TextField source='userName' label='User'/>
        <TextField source='team' label='Team'/>
        <DeleteButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  )
}

export default NamingsList
