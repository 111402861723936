import { AutocompleteInput, Filter, TextInput, useGetList } from 'react-admin';
import React, { useEffect, useState } from 'react';

const NamingsFilters = (props: any) => {
  const [groups, setGroups] = useState([]);

  const groupsList: any = useGetList(
    'groupsList',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (groupsList.data) {
      setGroups(groupsList.data?.map((record: any) => ({ id: record.name, name: record.name })));
    }
  }, [groupsList.data]);

  return (
    <Filter {...props} >
      <TextInput placeholder='Naming' source='naming' resettable alwaysOn />
      <TextInput placeholder='Pixel' source='pixel' resettable alwaysOn />
      <TextInput placeholder='User' source='userName' resettable alwaysOn />
      <AutocompleteInput
        placeholder='Team'
        source='team'
        choices={groups}
        alwaysOn
      />
    </Filter>
  )
};

export default NamingsFilters;
