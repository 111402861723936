import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton, FunctionField,
} from 'react-admin';
import PixelsFilters from './PixelsFilters';
import Tooltip from '@mui/material/Tooltip';

const NamingsList = (props: any) => {
  return (
    <List
      {...props}
      filters={<PixelsFilters />}
    >
      <Datagrid
        sx={{ overflowX: 'auto', maxWidth: '100%' }}
      >
        <TextField source='demoId' label='#' />
        <TextField source='pixel' />
        <FunctionField
          label='Token'
          render={(record: any) => {
            const truncatedText = record.token.substring(0, 20);
            const fullText = record.token;

            return (
              <Tooltip title={fullText}>
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {truncatedText} {truncatedText < fullText && '...'}
                </div>
              </Tooltip>
            );
          }}
        />
        <TextField source='user_name' label='User'/>
        <TextField source='user_team' label='Team'/>
        <DeleteButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  )
}

export default NamingsList
