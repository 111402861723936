import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  EditButton,
  Filter,
  SearchInput, Pagination, InfiniteList,
} from 'react-admin';

const CustomFilter = (props: any) => (
  <Filter {...props} >
    <SearchInput placeholder='Group name' source='name' resettable alwaysOn />
  </Filter>
);

const GroupPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 300, 500, 1000, { label: 'All', value: 1001 }]} />;

const GroupList = (props: any) => {
  return (
    <List
      {...props}
      filters={<CustomFilter />}
      pagination={<GroupPagination />}
    >
      <Datagrid>
        <TextField source='demoId' label='#' />
        <TextField source='botKey' />
        <TextField source='naming_id' label='bndrnt' />
        <TextField source='name' />
        <EditButton />
        <DeleteButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  )
}

export default GroupList
