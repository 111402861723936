import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  SimpleForm,
  TextInput,
  useGetList,
} from 'react-admin';

const GroupCreate = (props: any) => {
  // const [offersId, setOffersId] = useState([]);
  // const { data }: any = useGetList(
  //   'keitaro/offersID',
  // );

  // useEffect(() => {
  //   if (data) {
  //     setOffersId(data);
  //   }
  // }, [data]);

  return (
    <Create title='Create a Group' {...props}>
      <SimpleForm>
        {/*<AutocompleteArrayInput sx={{ width: 400 }} source='offers_id' choices={offersId} />*/}
        <TextInput source='name'  />
      </SimpleForm>
    </Create>
  )
}

export default GroupCreate
