import React from 'react';
import {
  DateInput,
  Edit,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { Grid, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const GroupEdit = (props: any) => {
  const choices = [
    { id: 'once_a_month', name: 'Once a month', tooltip: 'Once a month from the start date' },
    { id: 'twice_a_month', name: 'Twice a month', tooltip: 'Two dates per month: the 1st and 15th' },
    { id: 'weekly', name: 'Weekly', tooltip: 'Weekly on the same day of the week as start date' }
  ];

  const CustomTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .MuiTooltip-tooltip`]: {
      fontSize: '16px',
    },
  });

  return (
    <Edit
      title='Edit Group'
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm>
        <Grid container sx={{ pl: '10px', pr: '30px' }}>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Grid item xs={2} sx={{ display: 'flex' }} >
              <TextInput source='name' sx={{ width: '90%' }} />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex' }} >
              <TextInput source='naming_id' label='Bndrnt' sx={{ width: '90%' }} />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex' }} >
              <TextInput source='botKey' sx={{ width: '100%' }} />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', marginLeft: '30px' }} >
              <TextInput source='chat_id' label='Chat ID' sx={{ width: '50%' }} />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Grid item xs={3.7} sx={{ display: 'flex' }} >
              <TextInput
                source='info'
                label='Info (bot command)'
                sx={{ width: '95%' }}
                multiline
                minRows={4}
                fullWidth
                resettable
              />
            </Grid>
            <Grid item xs={3.7} sx={{ display: 'flex' }} >
              <TextInput
                source='finance_info'
                label='Info (autosend)'
                sx={{ width: '95%' }}
                multiline
                minRows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column' }} >
              <DateInput source='notification_start_date' sx={{ width: '80%', marginBottom: '-2px' }} />

              <SelectInput
                {...props}
                source="notification_frequency"
                choices={choices}
                optionText={(choice) => (
                  <CustomTooltip title={choice.tooltip} arrow>
                    <span>{choice.name}</span>
                  </CustomTooltip>
                )}
                optionValue="id"
                fullWidth
                resettable
                sx={{ width: '80%', marginTop: '0px' }}
                render={(props: any) => (
                  <TextField select {...props}>
                    {choices.map(choice => (
                      <MenuItem key={choice.id} value={choice.id}>
                        <CustomTooltip title={choice.tooltip} arrow>
                          <span>{choice.name}</span>
                        </CustomTooltip>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default GroupEdit
