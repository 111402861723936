import { Divider, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import React from 'react';
import GoogleIcon from '@mui/icons-material/Google';

const SimpleList = ({ items, theme }: any) => {
  const primaryTextColor = theme === 'dark' ? 'inherit' : 'textSecondary';

  return (
    <List>
      {items.map((item: any, index: number) => (
        <>
          <ListItem key={index}>
            <ListItemAvatar>
              <GoogleIcon fontSize='small' color='primary' />
            </ListItemAvatar>
            <span
              style={{ fontSize: '15px', marginRight: '10px', fontWeight: 'bold' }}
            >
              { index + 1 + '#'}
            </span>
            <ListItemText
              secondaryTypographyProps={{
                  'color': primaryTextColor
              }}
              primary={item.rk_id}
              secondary={item.rk_team}
            />
          </ListItem>
          <Divider />
        </>
        ))}
    </List>
  );
};

export default SimpleList
