import { AutocompleteInput, Filter, TextInput } from 'react-admin';
import React from 'react';

const CustomFilter = (props: any) => {

  return (
    <Filter {...props} >
      <TextInput placeholder='App name' source='name' resettable alwaysOn />
      <AutocompleteInput
        placeholder='Vertical'
        source='vertical'
        choices={[
          { id: 'Gambling', name: 'Gambling' },
          { id: 'Betting', name: 'Betting' },
          { id: 'Finance', name: 'Finance'},
          { id: 'Dating', name: 'Dating'},
        ]}
        alwaysOn
      />
    </Filter>
  )
};

export default CustomFilter;
