import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  SearchInput,
  FilterButton,
  TopToolbar,
  useGetList,
  AutocompleteInput,
  Pagination
} from 'react-admin';

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

const UserPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 300, 500]} />;

const UsersList = (props: any) => {
  const [groups, setGroups] = useState([]);

  const { data }: any = useGetList(
    'groupsList',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (data) {
      setGroups(data?.map((record: any) => ({ id: record.name, name: record.name })));
    }
  }, [data]);

  const usersFilters = [
    <SearchInput source='username' alwaysOn />,
    <AutocompleteInput source='groupName' choices={groups} />,
  ];

  return (
    <List
     {...props}
      pagination={<UserPagination />}
      filters={usersFilters}
      actions={<ListActions />}
    >
      <Datagrid>
        <TextField source='demoId' label='#' />
        <TextField source='chatId' label='Chat ID' />
        <TextField source='group' label='Group Key' />
        <TextField source='groupName' label='Group Name' />
        <TextField source='username' />
        <DeleteButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  );
};

export default UsersList;
