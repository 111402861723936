import * as React from 'react';
import { useEffect, useState } from 'react';
import { Title, useGetList } from 'react-admin';
import { Card, CardContent, TextField, Autocomplete } from '@mui/material';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Button from '@mui/material/Button';
import TableHead from '@mui/material/TableHead';
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { API_URL } from '../../App';

function TablePaginationActions(props: any) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const AppsRating = () => {
  const [apps, setApps] = useState([]);
  const [value, setValue] = React.useState<any>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [appRatings, setAppRatings] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - appRatings.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const appList: any = useGetList(
    'appsList',
  );

  useEffect(() => {
    if (appList.data) {
      setApps(appList.data?.map((record: any) => ({ ...record, label: record.name })));
    }
  }, [appList.data]);

  const handleClick = () => {
    handleOpen();

    fetch(`${API_URL}/appRating`, {
      method: 'POST',
      body: JSON.stringify({ appPackage: value.package }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
      .then(response => {
        setAppRatings(response.appRatingsByCountry);
        handleClose();
      })
      .catch(error => {
        handleClose();
      });
  };

  return (
    <Card sx={{ height: '100%' }}>
      <Title title='Apps Rating' />
      <CardContent>
        <h3 style={{ color: 'grey' }}>Checking the app rating:</h3>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box>
            <Autocomplete
              value={value}
              onChange={(event: any, newValue: string | null) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id='controllable-states-demo'
              options={apps}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label='App' />}
            />
          </Box>
          <Box sx={{ mt: '15px', ml: '20px' }}>
            <Button
              onClick={handleClick}
              variant='contained'>
              Check
            </Button>
          </Box>
          <Box sx={{ zIndex: 1 }}>
            <Backdrop
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </Box>
        </Box>
        {appRatings.length > 0 && (
          <Box sx={{ mt: '30px' }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell align="center">Country Code</TableCell>
                    <TableCell align="center">Rating</TableCell>
                    <TableCell align="center">Review Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                      ? appRatings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : appRatings
                  ).map((row: any) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Box sx={{ display: 'flex', fontSize: '18px' }}>
                          <LanguageIcon sx={{ mr: '10px' }} />
                          <div>
                            {row.country}
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{ fontSize: '18px' }}>
                          <div>
                            {row.countryCode}
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{ fontSize: '18px' }}>
                          <div>
                            {row.rating.toFixed(2)}
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px' }}>
                          <PersonIcon sx={{ mr: '10px' }} />
                          <div>
                            {row.reviewCount}
                          </div>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={appRatings.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        )
        }
      </CardContent>
    </Card>
  );
};

export default AppsRating;
